import React, { useState, useEffect } from "react";

import { makeStyles } from "@material-ui/core";

import logo from "../assets/img/logo_whitetrans_x1.png";
import logoSmall from "../assets/img/logoIconTrans.png";

import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";

import styles from "../assets/jss/mainHeaderStyles.js";
import IconButton from "@material-ui/core/IconButton";

import HelpOutlineIcon from "@material-ui/icons/HelpOutline";

import Button from "@material-ui/core/Button";

import Hidden from "@material-ui/core/Hidden";

const useClasses = makeStyles(styles);

export default function MainHeader(props) {
  const classes = useClasses();

  return (
    <AppBar position="static" className={classes.appBar}>
      <Toolbar className={classes.container}>
        <Button
          href="https://www.createcookbooks.com"
          target="_blank"
          className={classes.title}
          //color="transparent"
        >
          <img src={logo} style={{ height: "48px" }} />
        </Button>
        <IconButton
          href="https://www.createcookbooks.com/contact"
          target="_blank"
          color="inherit"
        >
          <HelpOutlineIcon />
        </IconButton>
      </Toolbar>
    </AppBar>
  );
}
