import React, { useEffect, useState } from "react";

import {
  createMuiTheme,
  ThemeProvider,
  makeStyles,
} from "@material-ui/core/styles";

import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";

import SweetAlert from "react-bootstrap-sweetalert";

import pageStyles from "./assets/jss/pageStyles.js";

import SubmitPopup from "./components/SubmitPopup.js";
import Main from "./views/Main.js";
import Default from "./views/Default.js";
import Disabled from "./views/Disabled.js";
import Error from "./views/Error.js";

import MainHeader from "./components/MainHeader.js";

import Grid from "@material-ui/core/Grid";

import { baseAPI } from "./base.js";
import CircularProgress from "@material-ui/core/CircularProgress";
import Edit from "./views/Edit.js";

import fontColorContrast from "font-color-contrast";

import defaultBg from "./assets/img/defaultBg.jpg";

const theme = createMuiTheme({
  overrides: {
    MuiFilledInput: {
      underline: {
        "&:after": {
          // focused
          borderBottomColor: "#00acc1",
        },
      },
    },
    MuiInputLabel: {
      root: {
        "&$focused": {
          color: "#00acc1",
        },
        "&$error": {
          color: "#f44336",
        },
      },
    },
    MuiSwitch: {
      colorPrimary: {
        "&$checked": {
          color: "#00acc1",
          "& + $track": {
            backgroundColor: "#00acc1",
          },
        },
      },
    },
    MuiCircularProgress: {
      root: {
        color: "#00acc1",
      },
    },
    MuiLinearProgress: {
      colorPrimary: {
        backgroundColor: "rgba(0, 172, 193, 0.2)",
      },
      barColorPrimary: {
        backgroundColor: "#00acc1",
      },
    },
    MuiAppBar: {
      colorDefault: {
        backgroundColor: "#ffffff",
        color: "#000000",
      },
    },
  },
});

const useStyles = makeStyles(pageStyles);

function PublicForm() {
  const classes = useStyles();

  const [loading, setLoading] = useState(true);

  const [error, setError] = useState(null);
  const [contact, setContact] = useState(null);
  const [bg, setBg] = useState(null);
  const [colour, setColour] = useState(null);

  const [prefill, setPrefill] = useState(null);
  const [selectedChapter, setSelectedChapter] = useState(null);

  const [publicFormData, setPublicFormData] = useState(null);

  const [editData, setEditData] = useState(null);

  const [bookData, setBookData] = useState(null);

  const path = window.location.pathname.slice(1);

  const pathArray = path.split("/");
  const slug = pathArray.length > 0 ? pathArray[0].toLowerCase() : null;

  const chapters = [
    "Breakfast",
    "Lunch",
    "Afternoon tea",
    "Dinner",
    "Desserts",
  ];

  /*useEffect(() => {
    if (slug.length > 0) {
      const docRef = app.firestore().collection("publicForm").doc(slug);

      docRef
        .get()
        .then((doc) => {
          if (doc.exists) {
            const data = doc.data();
            setPublicFormData(data);
            setLoading(false);
          } else {
            setLoading(false);
            setError({ code: 404 });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      setLoading(false);
    }
  }, []);*/

  useEffect(() => {
    if (path.length === 0) {
      setLoading(false);
    } else if (slug === "edit") {
      if (pathArray.length === 3) {
        const bookId = pathArray[1];
        const submissionId = pathArray[2];
        fetch(
          `${baseAPI}/public/retrieve-edit-recipe/${bookId}/${submissionId}`
        )
          .then((response) => {
            if (response.status === 200) {
              return response.json();
            } else {
              setError({ code: 500 });
              setLoading(false);
            }
          })
          .then((result) => {
            if (result.edit) {
              setEditData({ ...result, bookId, submissionId });
              if (result.title && result.chapter)
                window.document.title = `Edit ${result.chapter} recipe (${result.title}) — Create Cookbooks`;
            } else {
              const { title, description, contact, backgroundUrl } = result;
              if (title) window.document.title = `${title} — Create Cookbooks`;

              if (backgroundUrl) setBg(backgroundUrl);
              setError({ title, description, contact });
            }

            setLoading(false);
          })
          .catch((err) => {
            console.error(err);
            setError(err);
            setLoading(false);
          });
      } else {
        setError({ code: 404 });
        setLoading(false);
      }
    } else {
      fetch(`${baseAPI}/public/get-public-form-data/${slug}`)
        .then((response) => {
          if (response.status === 404) {
            setError({ code: 404 });
          } else {
            return response.json();
          }
        })
        .then((data) => {
          if (data) {
            if (data.publicFormData.title)
              window.document.title = `${data.publicFormData.title} — Create Cookbooks`;

            if (data.closed) {
              setPublicFormData(data.publicFormData);
            } else {
              setBookData(data.bookData);
              setPublicFormData(data.publicFormData);
            }
          }

          setLoading(false);
        })
        .catch((err) => {
          console.error(err);
          setError(err);
          setLoading(false);
        });
    }
  }, []);

  const handleOpen = (chapter) => {
    if (bookData) {
      const customFields = bookData.formSettings.customFields
        ? Object.keys(bookData.formSettings.customFields)
            .filter(
              (customField) =>
                bookData.formSettings.customFields[customField] !== null
            )
            .map(
              (customField) =>
                `&${customField}_label=${bookData.formSettings.customFields[customField].label}`
            )
            .join("")
        : "";

      setSelectedChapter(chapter.name);

      setPrefill(
        `chapter=${encodeURIComponent(
          chapter.chapterId
        )}&chapterName=${encodeURIComponent(
          chapter.name
        )}&slug=${slug}&bookID=${
          publicFormData.bookId
        }&fields=${bookData.formSettings.showFields.join(",")}${customFields}${
          publicFormData.allowEdit ? "&allowEdit=true" : ""
        }`
      );
    }
  };

  const handleClose = () => {
    setPrefill(null);
    setSelectedChapter(null);
  };

  if (loading) {
    return (
      <CircularProgress
        size={100}
        color="primary"
        className={classes.loadingSpinner}
      />
    );
  }

  return (
    <ThemeProvider theme={theme}>
      {editData ? (
        <div>
          <Edit editData={editData} />
        </div>
      ) : (
        <div>
          <MainHeader colour={publicFormData ? publicFormData.colour : null} />
          <div>
            <div
              className={classes.fullPage}
              style={
                bg ||
                (publicFormData &&
                  publicFormData.background &&
                  publicFormData.background.url)
                  ? {
                      backgroundImage:
                        "url(" + (bg || publicFormData.background.url) + ")",
                    }
                  : colour || (publicFormData && publicFormData.colour)
                  ? {
                      backgroundColor: publicFormData.colour,
                    }
                  : {
                      backgroundImage: "url(" + defaultBg + ")",
                    }
              }
            >
              {publicFormData && bookData && publicFormData.open ? (
                <Main
                  title={publicFormData.title}
                  description={publicFormData.description}
                  chapters={bookData.chapters}
                  openChapter={handleOpen}
                  colour={publicFormData.colour}
                  contact={publicFormData.contact}
                />
              ) : publicFormData && !publicFormData.open ? (
                <Disabled
                  title={publicFormData.title}
                  description={publicFormData.description}
                />
              ) : error ? (
                <Error error={error} />
              ) : (
                <Default />
              )}
              <a
                href="https://www.createcookbooks.com"
                target="_blank"
                style={{
                  color: colour
                    ? fontColorContrast(colour)
                    : publicFormData && publicFormData.backgroundColor
                    ? fontColorContrast(publicFormData.backgroundColor)
                    : "white",
                  zIndex: 3,
                }}
              >
                Powered by Create Cookbooks
              </a>
            </div>
          </div>
          {prefill ? (
            <SubmitPopup
              chapter={selectedChapter}
              prefill={prefill}
              onClose={handleClose}
              paperformID={publicFormData.paperformID}
            />
          ) : null}
        </div>
      )}
    </ThemeProvider>
  );
}

export default PublicForm;
