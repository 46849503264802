import React, { useEffect, useState } from "react";

import {
  createMuiTheme,
  ThemeProvider,
  makeStyles,
} from "@material-ui/core/styles";

import cardStyles from "../assets/jss/cardStyles.js";

import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Button from "@material-ui/core/Button";
import Link from "@material-ui/core/Link";

import Typography from "@material-ui/core/Typography";

import Grid from "@material-ui/core/Grid";
import Divider from "@material-ui/core/Divider";

import logo from "../assets/img/logo_whitetrans_x1.png";

import fontColorContrast from "font-color-contrast";
import LinearProgress from "@material-ui/core/LinearProgress";

import ReactMarkdown from "react-markdown";

const useStyles = makeStyles(cardStyles);

export default function Main(props) {
  const classes = useStyles();

  const { title, description, chapters, openChapter, colour, contact } = props;

  return (
    <div className={classes.container}>
      <Grid container justify="center">
        <Grid item xs={12} style={{ margin: "0 auto" }}>
          <Card variant="outlined" className={classes.cardPlain}>
            <Card className={classes.card}>
              <CardContent>
                <h1 style={{ margin: 0 }}>{title}</h1>
                {description ? (
                  <>
                    <Divider style={{ margin: "20px" }} />
                    <ReactMarkdown linkTarget="_blank" skipHtml={true}>
                      {description}
                    </ReactMarkdown>
                  </>
                ) : null}
              </CardContent>
              <CardContent>
                {contact ? (
                  <div style={{ paddingBottom: "15px" }}>
                    <Link
                      component="button"
                      data-paperform-id="cc-public-contact"
                      data-popup-button="1"
                      data-prefill={`primaryEmail=${contact.primaryEmail}${
                        contact.cc ? `&cc=${contact.cc}` : ""
                      }`}
                    >
                      <Typography>Contact us</Typography>
                    </Link>
                  </div>
                ) : null}
              </CardContent>
              <Grid container justify="center">
                <Typography variant="h6">
                  Select a chapter to submit a recipe
                </Typography>
              </Grid>
              <CardContent>
                {chapters ? (
                  <Grid
                    container
                    direction="row"
                    justify="center"
                    alignItems="stretch"
                    spacing={2}
                  >
                    {chapters.map((chapter) => (
                      <Grid item key={chapter.chapterId}>
                        <Button
                          size="large"
                          variant="contained"
                          disableElevation={true}
                          onClick={() => openChapter(chapter)}
                          style={
                            colour
                              ? {
                                  backgroundColor: colour,
                                  color: fontColorContrast(colour),
                                  width: "200px",
                                  height: "100%",
                                }
                              : { width: "200px", height: "100%" }
                          }
                        >
                          {chapter.name}
                        </Button>
                      </Grid>
                    ))}
                  </Grid>
                ) : (
                  <LinearProgress color="primary" />
                )}
              </CardContent>
            </Card>
          </Card>
        </Grid>
      </Grid>
    </div>
  );
}
