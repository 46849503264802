import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import ListItemText from "@material-ui/core/ListItemText";
import ListItem from "@material-ui/core/ListItem";
import List from "@material-ui/core/List";
import Divider from "@material-ui/core/Divider";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";
import Slide from "@material-ui/core/Slide";

import Grid from "@material-ui/core/Grid";

import HelpIcon from "@material-ui/icons/Help";

import logoIcon from "../assets/img/logoIcon.png";

import CircularProgress from "@material-ui/core/CircularProgress";

import HexToRgb from "./HexToRgb.js";

import LinearProgress from "@material-ui/core/LinearProgress";
import Paperform from "./Paperform";

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: "relative",
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
    textTransform: "unset",
  },
  logoIcon: {
    height: "3rem",
  },
  iframeContainer: {
    height: "100%",
    backgroundColor: "#EEEEEE",
  },
  iframe: {
    height: "100%",
    width: "100%",
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return (
    <Slide direction="up" ref={ref} {...props} mountOnEnter unmountOnExit />
  );
});

export default function SubmitPopup(props) {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [loaded, setLoaded] = useState(false);

  const [saved, setSaved] = useState(true);

  const { chapter, prefill, onClose, paperformID } = props;

  useEffect(() => {
    setOpen(true);
  }, []);

  useEffect(() => {
    //console.log(HexToRgb("#fcba03"));
    if (!saved) {
      window.onpopstate = (event) => {
        return false;
      };

      window.onbeforeunload = (event) => {
        return false;
      };
    } else {
      window.onpopstate = undefined;
      window.onbeforeunload = undefined;
    }

    return () => {
      window.onpopstate = undefined;
      window.onbeforeunload = undefined;
    };
  }, [saved]);

  const handleClose = () => {
    if (!saved) {
      const confirm = window.confirm("Are you sure? Any changes will be lost.");
      if (confirm) setOpen(false);
    } else {
      setOpen(false);
    }
  };

  const onLoad = (e) => {
    if (!loaded) setLoaded(true);
    setSaved(false);
  };

  const defaultPaperformID = "cc-public";

  return (
    <Dialog
      fullScreen
      open={open}
      onClose={handleClose}
      TransitionComponent={Transition}
      onExited={onClose}
    >
      <AppBar color="default" className={classes.appBar}>
        <Toolbar>
          <a href="https://www.createcookbooks.com" target="_blank">
            <img src={logoIcon} className={classes.logoIcon} />
          </a>

          <Typography variant="h5" className={classes.title}>
            Submit recipe — <i>{chapter}</i>
          </Typography>

          <IconButton
            edge="start"
            color="inherit"
            onClick={handleClose}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      {!loaded ? (
        <LinearProgress color="primary" style={{ zIndex: 9999 }} />
      ) : null}

      <div
        className={classes.iframeContainer}
        style={{ marginTop: loaded ? "0" : "-4px" }}
      >
        <Paperform
          paperformID={paperformID || defaultPaperformID}
          prefill={prefill}
          hasPrefill={true}
          onLoad={onLoad}
          onSubmission={() => setSaved(true)}
        />
      </div>
    </Dialog>
  );
}
