import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import ListItemText from "@material-ui/core/ListItemText";
import ListItem from "@material-ui/core/ListItem";
import List from "@material-ui/core/List";
import Divider from "@material-ui/core/Divider";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";
import Slide from "@material-ui/core/Slide";

import Grid from "@material-ui/core/Grid";

import HelpIcon from "@material-ui/icons/Help";

import logoIcon from "../assets/img/logoIcon.png";

import CircularProgress from "@material-ui/core/CircularProgress";

import HexToRgb from "../components/HexToRgb.js";

import HelpOutlineIcon from "@material-ui/icons/HelpOutline";

import LinearProgress from "@material-ui/core/LinearProgress";
import Paperform from "../components/Paperform";

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: "relative",
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
    textTransform: "unset",
  },
  logoIcon: {
    height: "3rem",
  },
  iframeContainer: {
    height: "100%",
    backgroundColor: "#ffffff",
  },
  iframe: {
    height: "100%",
    width: "100%",
  },
}));

export default function Edit(props) {
  const classes = useStyles();

  const [loaded, setLoaded] = useState(false);

  const [prefill, setPrefill] = useState(null);

  const [saved, setSaved] = useState(true);

  const {
    chapter,
    showFields,
    customFields,
    recipeId,
    recipeArray,
    paperformID,
    bookId,
    slug,
    imageCount,
  } = props.editData;

  useEffect(() => {
    setPrefill(
      `embed=0&edit=${recipeId}&chapterName=${chapter}&${recipeArray
        .map(({ key, value }) => {
          if (
            key.startsWith("custom_field") &&
            !(customFields && customFields[key])
          ) {
            return `${key}=${encodeURIComponent(value)}&${key}_label=${key
              .split("_")
              .map((word) => word[0].toUpperCase() + word.slice(1))
              .join(" ")}`;
          }

          return `${key}=${value}`;
        })
        .join("&")}&fields=${showFields
        .map((field) =>
          field.startsWith("custom_field") ? "customFields" : field
        )
        .join(",")}${
        customFields
          ? Object.keys(customFields)
              .filter((customField) => customFields[customField] !== null)
              .map(
                (customField) =>
                  `&${customField}_label=${customFields[customField].label}`
              )
              .join("")
          : ""
      }&bookID=${bookId}&slug=${slug}&imageCount=${imageCount}`
    );
  }, []);

  useEffect(() => {
    if (!saved) {
      window.onpopstate = (event) => {
        return false;
      };

      window.onbeforeunload = (event) => {
        return false;
      };
    } else {
      window.onpopstate = undefined;
      window.onbeforeunload = undefined;
    }

    return () => {
      window.onpopstate = undefined;
      window.onbeforeunload = undefined;
    };
  }, [saved]);

  const onLoad = (e) => {
    if (!loaded) setLoaded(true);
    setSaved(false);
  };

  const defaultPaperformID = "cc-public";

  return (
    <Dialog fullScreen open={true}>
      <AppBar color="default" className={classes.appBar}>
        <Toolbar>
          <a href="https://www.createcookbooks.com" target="_blank">
            <img src={logoIcon} className={classes.logoIcon} />
          </a>

          <Typography variant="h5" className={classes.title}>
            Edit recipe — <i>{chapter}</i>
          </Typography>

          <IconButton
            edge="start"
            color="inherit"
            href="https://createcookbooks.zendesk.com"
            target="_blank"
            aria-label="close"
          >
            <HelpOutlineIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      {!loaded ? (
        <LinearProgress color="primary" style={{ zIndex: 9999 }} />
      ) : null}

      <div
        className={classes.iframeContainer}
        style={{ marginTop: loaded ? "0" : "-4px" }}
      >
        <Paperform
          paperformID={paperformID || defaultPaperformID}
          prefill={prefill}
          hasPrefill={true}
          onLoad={onLoad}
          onSubmission={() => setSaved(true)}
        />
      </div>
    </Dialog>
  );
}
