const cardStyles = (theme) => ({
  container: {
    paddingRight: "15px",
    paddingLeft: "15px",
    marginRight: "auto",
    marginLeft: "auto",
    "&:before,&:after": {
      display: "table",
      content: '" "',
    },
    "&:after": {
      clear: "both",
    },
    position: "relative",
    zIndex: "4",
    paddingBottom: "120px",
  },
  card: {
    textAlign: "center",
    margin: "4px",
  },
  cardPlain: {
    background: "transparent",
    boxShadow: "none",

    border: "5px solid #ffffff",
    borderRadius: "10px",
    maxWidth: "700px",
    marginBottom: "100px",
    //marginTop: "15vh",
  },
});

export default cardStyles;
