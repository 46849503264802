/*eslint-disable*/
import React, { useEffect, useState, useRef } from "react";

export default function Paperform(props) {
  const {
    paperformID,
    prefill,
    hasPrefill,
    onLoad,
    onSubmission,
    disableLoadingBar,
  } = props;

  const [loading, setLoading] = useState(true);
  const paperFormDiv = useRef(null);

  const handleSubmission = (e) => {
    if (onSubmission) {
      onSubmission(e.detail.data);
    }
  };

  useEffect(() => {
    window.addEventListener("PaperformSubmission", handleSubmission);

    return () => {
      window.removeEventListener("PaperFormSubmission", handleSubmission);
    };
  }, []);

  useEffect(() => {
    if (prefill) {
      if (paperFormDiv.current) {
        setTimeout(() => {
          if (paperFormDiv.current) {
            const iframe = paperFormDiv.current.querySelector("iframe");

            iframe.onload = function () {
              onIframeLoad();
            };

            //iframe.src = iframe.src.replace("embed=1", "embed=0");
          }
        }, 250);
      }
    } else {
      setLoading(true);
    }
  }, [prefill]);

  const onIframeLoad = () => {
    if (loading) {
      setLoading(false);
      if (onLoad) onLoad();
    }
  };

  return (
    <>
      {hasPrefill && prefill ? (
        <div
          className="paperform-iframe"
          data-prefill={`embed=0&${prefill}`}
          ref={paperFormDiv}
          data-paperform-id={paperformID}
          data-spinner={true}
          data-lazy={true}
        />
      ) : null}
    </>
  );
}
