import React, { useEffect, useState } from "react";

import {
  createMuiTheme,
  ThemeProvider,
  makeStyles,
} from "@material-ui/core/styles";

import cardStyles from "../assets/jss/cardStyles.js";

import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";

import Grid from "@material-ui/core/Grid";
import { Divider, Link } from "@material-ui/core";

import logo from "../assets/img/logo_whitetrans_x1.png";

import fontColorContrast from "font-color-contrast";

const useStyles = makeStyles(cardStyles);

export default function Error404(props) {
  const classes = useStyles();

  const { error } = props;

  useEffect(() => {
    console.log(error);
  });

  return (
    <div className={classes.container}>
      <Grid container justify="center">
        <Grid item xs={12} style={{ margin: "0 auto" }}>
          <Card variant="outlined" className={classes.cardPlain}>
            <Card className={classes.card}>
              {error && (error.title || error.description) ? (
                <CardContent>
                  {error.title ? (
                    <>
                      <Typography variant="h4">{error.title}</Typography>
                      <Divider style={{ margin: "30px" }} />
                    </>
                  ) : null}
                  {error.description ? (
                    <Typography>{error.description}</Typography>
                  ) : null}
                  <div style={{ marginTop: "30px" }}>
                    <Link component="a" href="https://cc.recipes">
                      Return home
                    </Link>
                  </div>
                </CardContent>
              ) : error && error.code === 404 ? (
                <CardContent>
                  <Typography variant="h4">404 form not found</Typography>
                  <Divider style={{ margin: "30px" }} />
                  <Typography>We could not find your form.</Typography>
                  <Typography>Please check the URL and try agin.</Typography>
                  <div style={{ marginTop: "30px" }}>
                    <Link component="a" href="https://cc.recipes">
                      Return home
                    </Link>
                  </div>
                </CardContent>
              ) : (
                <CardContent>
                  <Typography variant="h4">Something went wrong...</Typography>
                  <Divider style={{ margin: "30px" }} />
                  <Typography>
                    There was a server error, please try again.
                  </Typography>
                  <div style={{ marginTop: "30px" }}>
                    <Link component="a" href="https://cc.recipes">
                      Return home
                    </Link>
                  </div>
                </CardContent>
              )}
            </Card>
          </Card>
        </Grid>
      </Grid>
    </div>
  );
}
