import React, { useEffect, useState } from "react";

import {
  createMuiTheme,
  ThemeProvider,
  makeStyles,
} from "@material-ui/core/styles";

import cardStyles from "../assets/jss/cardStyles.js";

import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";

import Grid from "@material-ui/core/Grid";
import { Divider, InputAdornment, Link, TextField } from "@material-ui/core";

import logo from "../assets/img/logo_whitetrans_x1.png";

import fontColorContrast from "font-color-contrast";

const useStyles = makeStyles(cardStyles);

export default function Error404(props) {
  const classes = useStyles();

  const [slug, setSlug] = useState("");

  const openWindow = () => {
    if (!slug) return;

    window.open(`https://cc.recipes/${slug}`, "_blank");
  };

  const { error } = props;

  return (
    <div className={classes.container}>
      <Grid container justify="center">
        <Grid item xs={12} style={{ margin: "0 auto" }}>
          <Card variant="outlined" className={classes.cardPlain}>
            <Card className={classes.card}>
              <CardContent>
                <Typography variant="h2">Submit recipe form</Typography>
                <Divider style={{ margin: "30px" }} />
                <Typography
                  variant="h6"
                  style={{ paddingLeft: "60px", paddingRight: "60px" }}
                >
                  Type the shared code below to open your recipe form
                </Typography>
              </CardContent>
              <CardContent>
                <Grid container justify="center" alignItems="center">
                  <Grid item>
                    <Typography
                      variant="h2"
                      style={{ fontSize: "36px", paddingRight: "5px" }}
                    >
                      cc.recipes/
                    </Typography>
                  </Grid>
                  <Grid item>
                    <TextField
                      variant="outlined"
                      style={{ width: "200px" }}
                      InputProps={{
                        onChange: (e) =>
                          setSlug(e.target.value.toLowerCase().trim()),
                        onKeyDown: (e) =>
                          e.key === "Enter" ? openWindow() : null,
                        endAdornment: (
                          <InputAdornment position="end">
                            <Button disabled={!slug} onClick={openWindow}>
                              Open
                            </Button>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                </Grid>
              </CardContent>
              <CardContent style={{ marginTop: "20px" }}>
                <Typography variant="p">
                  Not what you're looking for?
                </Typography>
                <div style={{ marginTop: "10px" }}>
                  <Link
                    component="a"
                    href="https://www.createcookbooks.com"
                    target="_blank"
                  >
                    Click here to create a free cookbook
                  </Link>
                </div>
              </CardContent>
            </Card>
          </Card>
        </Grid>
      </Grid>
    </div>
  );
}
