const firebase = window.firebase;

const baseAPI =
  window.location.hostname === "localhost"
    ? "http://localhost:5000"
    : "https://api.createcookbooks.com";

const app = firebase;

export { app, baseAPI };
