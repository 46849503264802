import React, { useEffect, useState } from "react";

import {
  createMuiTheme,
  ThemeProvider,
  makeStyles,
} from "@material-ui/core/styles";

import cardStyles from "../assets/jss/cardStyles.js";

import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";

import Grid from "@material-ui/core/Grid";
import { Divider } from "@material-ui/core";

import logo from "../assets/img/logo_whitetrans_x1.png";

import fontColorContrast from "font-color-contrast";

const useStyles = makeStyles(cardStyles);

export default function Error404(props) {
  const classes = useStyles();

  const { title, description } = props;

  return (
    <div className={classes.container}>
      <Grid container justify="center">
        <Grid item xs={12} style={{ margin: "0 auto" }}>
          <Card variant="outlined" className={classes.cardPlain}>
            <Card className={classes.card}>
              <CardContent style={{ whiteSpace: "pre-line" }}>
                <Typography variant="h4">{title}</Typography>
                {title && description ? (
                  <Divider style={{ margin: "30px" }} />
                ) : null}
                <Typography>{description}</Typography>
              </CardContent>
            </Card>
          </Card>
        </Grid>
      </Grid>
    </div>
  );
}
